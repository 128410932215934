import React, { useState } from "react";
import PropTypes from "prop-types";
import rehypeReact from "rehype-react";
import { graphql } from "gatsby";

import LyricsTable, { Track, Total, LyricsDialog } from "./Lyrics";

import Layout from "../../components/Layout";
import Container from "../../components/Container";
import Background from "../../components/Background";
import Article from "../../components/Article";
import Content from "../../components/Article/Content";
import Header from "../../components/Article/Header";

const useDialogState = (initialState = { isOpen: false, title: "", lyrics: "" }) => {
  const [dialogProps, setDialogProps] = useState(initialState);

  return {
    dialogProps,
    open: ({ title, lyrics }) => setDialogProps({ isOpen: true, title, lyrics }),
    close: () => setDialogProps({ isOpen: false, title: "", lyrics: "" }),
  };
};

const LyricsPage = ({ data, location }) => {
  const { page = {} } = data;
  const pageTitle = (page.frontmatter || {}).title;
  const bgPosition = page.frontmatter.bgPosition || "66% center";

  const { dialogProps, open, close } = useDialogState();

  // eslint-disable-next-line new-cap
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      "track-list": LyricsTable,
      // eslint-disable-next-line react/display-name, react/prop-types
      "track-list-row": ({ children, ...otherProps }) => (
        <Track {...otherProps} openLyrics={open}>
          {children}
        </Track>
      ),
      "track-list-total": Total,
    },
  }).Compiler;

  return (
    <Layout location={location}>
      <Container location={location}>
        <Background
          fluid={data.image.childImageSharp.fluid}
          objectFit="cover"
          objectPosition={bgPosition}
        />

        <Article>
          <Header title={pageTitle} />
          <Content style={{ maxWidth: "none" }}>{renderAst(page.htmlAst)}</Content>
        </Article>
      </Container>
      <LyricsDialog {...dialogProps} onClose={close} />
    </Layout>
  );
};

LyricsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default LyricsPage;

export const pageQuery = graphql`
  query($slug: String!, $bgImg: String) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        bgPosition
      }
    }
    image: file(relativePath: { eq: $bgImg }) {
      ...BackgroundImageFragment
    }
  }
`;
