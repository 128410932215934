import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  Slide,
  Button,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SubjectIcon from "@material-ui/icons/Subject";
// import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const LyricsDialog = withStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { title, lyrics, onClose, isOpen = false, classes } = props;

  return (
    <Dialog fullScreen TransitionComponent={Transition} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" align="center" gutterBottom>
          {title}
        </Typography>
        <DialogContentText align="center" component="div">
          {lyrics}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
});

LyricsDialog.propTypes = {
  title: PropTypes.string.isRequired,
  lyrics: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export const Track = ({ children, number, duration, title, openLyrics }) => (
  <TableRow hover={true} onClick={() => openLyrics({ title, lyrics: children })}>
    <TableCell>{`${number}.`}</TableCell>
    <TableCell>{title}</TableCell>
    <TableCell align="right">
      <Button variant="text" size="small" style={{ color: "#fff" }} title="Open lyrics">
        <SubjectIcon />
      </Button>
    </TableCell>
  </TableRow>
);

Track.propTypes = {
  children: PropTypes.node.isRequired,
  number: PropTypes.string.isRequired,
  duration: PropTypes.string,
  title: PropTypes.string.isRequired,
  openLyrics: PropTypes.func,
};

export const Total = ({ children, duration, paddingTop = "1em" }) => (
  <TableRow>
    <TableCell colSpan="2" style={{ paddingTop }}>
      {children}
    </TableCell>
    <TableCell align="right" style={{ paddingTop }}>
      {duration}
    </TableCell>
  </TableRow>
);

Total.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.string.isRequired,
  paddingTop: PropTypes.string,
};

const LyricsTable = ({ children }) => {
  // remove whitespaces
  const body = children.filter((item) => typeof item !== "string" || item.trim());

  return (
    <Table padding="none" className="table-no-border">
      <TableBody>{body}</TableBody>
    </Table>
  );
};

LyricsTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LyricsTable;
